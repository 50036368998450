import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye} from '@fortawesome/free-regular-svg-icons';

import {
    cameraClearState,
    cameraCreate,
    cameraCurrentClearState,
    cameraDelete,
    cameraEdit,
    cameraFlatDetailedList,
    cameraLoad,
    dashcamLoad
} from '../store/Camera';
import { videoRecorderDelete } from '../store/VideoRecorder'
import {loadObjectsObservationList, objectObservationClearState} from '../store/ObjectObservation';
import {apiDataToCameras, cameraToApiData, omitRooms} from '../utils/utils';
import {nbsp} from '../utils/string';
import GeneralObjectsTable from '../tables/GeneralObjectsTable';
import SelectWithButtons from '../tables/SelectWithButtons';
import TextInputWithButtons from '../tables/TextInputWithButtons';
import CredentialsInputWithButtons from '../tables/CredentialsInputWithButtons';
import withDataErrorIndication from '../components/withDataErrorIndication';
import CheckboxWithButtons from '../tables/CheckboxWithButtons';
import CameraModal from "../components/CameraModal";

const HiddenTextFormatter = ({text}) => {
    const [isTextHidden, setIsTextHidden] = useState(true);

    return (
        <div onMouseDown={() => setIsTextHidden(false)}
             onMouseUp={() => setIsTextHidden(true)}>
            {isTextHidden ? <FontAwesomeIcon icon={faEye}/> : <>{text || nbsp}</>}
        </div>
    );
}

const CamerasManagement = ({actions, cameras, camera, objects = [], isListPending}) => {
    const {loadAllCameras, createCamera, updateCamera, deleteCamera, loadObjects, loadCamera, clearCamera} = actions;

    const loadData = () => {
        loadAllCameras();
        loadObjects();
    }

    const [shouldShowModal, setShowModal] = useState(false);
    const [isEditModal, setEditModal] = useState(false)
    const [cameraActive, setCameraActive] = useState();

    useEffect(() => {
        loadData();
    }, [])

    // useEffect(() => {
    //     if (camera.isReloadList) {
    //         loadAllCameras();
    //     }
    // }, [camera]);

    // useEffect(() => {
    //     if (camera && camera.id) {
    //         setShowModal(true);
    //     }
    // }, [camera]);

    const Columns = (cellCancelHandler) => [{
        text: '№',
        dataField: 'id',
        sort: true,
        headerStyle: () => ({width: '59px'}),
        editable: false,
    }, {
        text: 'Блок',
        dataField: 'power_unit',
        editable: false,
        headerStyle: () => ({width: '200px'}),
        formatter: (cell) => cell.name,
        required: true,
    }, {
        text: 'Помещение',
        dataField: 'object_name',
        editable: false,
        headerStyle: () => ({width: '200px'}),
        type: 'number',
        required: true,
    }, {
        text: 'Название',
        dataField: 'name',
        sort: true,
        editable: false,
        editorRenderer: (editorProps) => (
            <TextInputWithButtons
                {...editorProps}
                onCancel={cellCancelHandler} className="required-field"/>
        ),
        headerStyle: () => ({width: '200px'}),
        required: true,
    }, {
        text: 'Протокол',
        dataField: 'protocol',
        editable: false,
        editorRenderer: (editorProps) => (
            <SelectWithButtons
                {...editorProps}
                onCancel={cellCancelHandler}
                options={[
                    'rtsp://', 'file:///', 'https://', 'http://'
                ].reduce((acc, protocol) => ({...acc, [protocol]: protocol}), {})}
                className="required-field"/>
        ),
        headerStyle: () => ({width: '100px'}),
        required: true,
    }, {
        text: 'Хост',
        dataField: 'host',
        editable: false,
        editorRenderer: (editorProps) => (
            <TextInputWithButtons
                {...editorProps}
                onCancel={cellCancelHandler} className="required-field"/>
        ),
        headerStyle: () => ({width: '130px'}),
        required: true,
    }, {
        text: '',
        dataField: '',
        editable: false,
        headerStyle: () => ({width: '10px'}),
        formatter: () => <>/</>,
        isDummyField: true,
        classes: 'dummy-column',
    }, {
        text: 'Суффикс',
        dataField: 'suffix',
        editable: false,
        editorRenderer: (editorProps) => (
            <TextInputWithButtons {...editorProps} onCancel={cellCancelHandler}/>
        ),
        required: true,
    }, {
        text: 'Логин и пароль',
        dataField: 'credentials',
        editable: false,
        formatter: (cell) => (
            <HiddenTextFormatter text={cell.join(':')}/>
        ),
        editorRenderer: (editorProps) => (
            <CredentialsInputWithButtons
                {...editorProps} onCancel={cellCancelHandler}/>
        ),
        headerStyle: () => ({width: '230px'}),
    }, {
        text: 'Вкл',
        dataField: 'is_enabled',
        sort: true,
        editable: false,
        editorRenderer: (editorProps) => (
            <CheckboxWithButtons {...editorProps} onCancel={cellCancelHandler}/>
        ),
        formatter: (cell) => <input type="checkbox" checked={cell} className="tableCheckbox"/>,
        type: 'bool',
        headerStyle: () => ({width: '50px'}),
    }];

    document.title = 'Камеры';

    return (
        <>
            <GeneralObjectsTable
                isPending={isListPending}
                objects={apiDataToCameras(cameras)}
                isEditable={false}
                actions={{
                    update: updateCamera,
                    // create: createCamera,
                    create: () => {
                        setShowModal(true);
                    },
                    purge: deleteCamera,
                    reload: loadData,
                }}
                getColumns={Columns}
                itemDummy={{
                    id: 0,
                    code: 0,
                    obj: '',
                    name: '',
                    protocol: '',
                    host: '',
                    suffix: '',
                    credentials: [],
                    is_enabled: true,
                }}
                rowEvents={{
                    onDoubleClick: (e, row, rowIndex) => {
                        loadCamera(row);
                        // setCameraActive(row);
                        setShowModal(true);
                        setEditModal(true)
                    }
                }}
            />
            {shouldShowModal &&
            <CameraModal
                objectsList={objects}
                // cameraActive={cameraActive}
                onCancel={() => {
                    setEditModal(false)
                    setShowModal(false);
                    clearCamera();
                }}
                onClose={() => {
                    setEditModal(false)
                    setShowModal(false);
                    clearCamera();
                }}
                isEditModal={isEditModal}
            />}
        </>
    )
}

export default connect(
    state => ({
        user: state.user,
        cameras: state.camera.flatList,
        camera: state.camera.current,
        isListPending: state.camera.isListPending || state.object.isListPending,
        objects: state.object.list,
        isError: state.camera.isListError,
    }),
    dispatch => ({
        actions: {
            loadAllCameras: () => {
                dispatch(cameraClearState());
                dispatch(cameraFlatDetailedList());
            },
            createCamera: (camera) => {
                const apiData = cameraToApiData(camera);
                dispatch(cameraCreate(camera.object, apiData));
            },
            loadCamera: (camera) => {
                dispatch(cameraCurrentClearState());
                camera?.object_name?.includes('Видеорегистратор') ? dispatch(dashcamLoad(camera.id)) : dispatch(cameraLoad(camera.object, camera.id));
            },
            updateCamera: (cameraId, camera) => {
                const apiData = cameraToApiData(camera);
                dispatch(cameraEdit(camera.obj, cameraId, apiData));
            },
            deleteCamera: (cameraId, camera) => {
                camera?.object_name?.includes('Видеорегистратор') ? dispatch(videoRecorderDelete(cameraId)).then(() => {
                    dispatch(cameraClearState());
                    dispatch(cameraFlatDetailedList());
                }) : dispatch(cameraDelete(camera.object, cameraId));
            },
            loadObjects: () => {
                dispatch(objectObservationClearState());
                dispatch(loadObjectsObservationList());
            },
            clearCamera: () => {
                dispatch(cameraCurrentClearState());
            },
        },
    }),
)(withDataErrorIndication(CamerasManagement));
