import axios from 'axios';
import imageUtils from "../utils/imageUtils";
import {clearEmploeesState, loadEmploeesList} from "./Employees";

const VIDEO_RECORDER = {
    LIST:       'VIDEO_RECORDER_LIST',
    LIST_PENDING: 'VIDEO_RECORDER_LIST_PENDING',
    LIST_ERROR: 'VIDEO_RECORDER_LIST_ERROR',
    DETAIL:     'VIDEO_RECORDER_DETAIL',
    CREATE:     'VIDEO_RECORDER_CREATE',
    EDIT:       'VIDEO_RECORDER_EDIT',
    DELETE:     'VIDEO_RECORDER_DELETE',
    CLEAR:      'VIDEO_RECORDER_CLEAR',
    CURRENT_CLEAR:      'VIDEO_RECORDER_CURRENT_CLEAR',
};

const initialState = {
    current: {},
};

const videoRecorderReducer = (state = initialState, action) => {
    switch(action.type) {
        case VIDEO_RECORDER.LIST:
            return {
                ...state,
                // list: action.payload,
                list: action.payload.map(camera => {return {...camera, obj: camera.power_unit_info}}),
                isListPending: false,
                isListError: false,
            };
        case VIDEO_RECORDER.DETAIL:
        case VIDEO_RECORDER.CREATE:
        case VIDEO_RECORDER.EDIT:
        case VIDEO_RECORDER.DELETE:
        case VIDEO_RECORDER.ALL_INFO:
            return {
                ...state,
                current: action.payload,
                isListPending: false,
            };
        case VIDEO_RECORDER.LIST_PENDING:
            return {
                ...state,
                isListPending: true,
            }
        case VIDEO_RECORDER.LIST_ERROR:
            return {
                isListError: true,
            }
        case VIDEO_RECORDER.CLEAR:
            return initialState;
        case VIDEO_RECORDER.CURRENT_CLEAR:
            return {
                ...state,
                current: []
            };
        default:
            return state;
    }
};

export default videoRecorderReducer;

export const videoRecorderList = () => dispatch => {
    dispatch({
        type: VIDEO_RECORDER.LIST_PENDING,
        payload: null,
    });
    axios.get('/api/statistics/kola-dashcam/dashcams/')
        .then(res => {
            dispatch({
                type: VIDEO_RECORDER.LIST,
                payload: res.data
            });
        })
};


/* Загрузка камеры (cameraId) из объекта наблюдения (objId) */
export const videoRecorderLoad = (cameraId) => dispatch => {
    dispatch({
        type: VIDEO_RECORDER.LIST_PENDING,
        payload: null,
    });
    axios.get(`/api/statistics/kola-dashcam/dashcams/${cameraId}/`)
        .then(res => {
            dispatch({
                type: VIDEO_RECORDER.DETAIL,
                payload: res.data
            });
        });
};

/* Создание новой камеры */
export const videoRecorderCreate = (data) => dispatch => {
    axios.post(`/api/statistics/kola-dashcam/dashcams/`, data)
        .then(res => {
            dispatch({
                type: VIDEO_RECORDER.CREATE,
                payload: {isReloadList: false},
            });
            dispatch(videoRecorderList());
        })
        .catch(err => {
            dispatch({
                type: VIDEO_RECORDER.CREATE,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString()
                },
            });
        });
};

export const videoRecorderDelete = (cameraId) => dispatch => {
   return axios.delete(`/api/statistics/kola-dashcam/dashcams/${cameraId}/`)
        .then(res => {
            let data = {isReloadList: false};
            dispatch({
                type: VIDEO_RECORDER.DELETE,
                payload: data,
            });
            dispatch(videoRecorderClearState());
            dispatch(videoRecorderList());
        });
};

export const videoRecorderClearState = () => dispatch => {
    dispatch({
        type: VIDEO_RECORDER.CLEAR,
        payload: null,
    });
};

export const videoRecorderCurrentClearState = () => dispatch => {
    dispatch({
        type: VIDEO_RECORDER.CURRENT_CLEAR,
        payload: null,
    });
};

export const videoRecorderEdit = (cameraId, data) => dispatch => {
    axios.put(`/api/statistics/kola-dashcam/dashcams/${cameraId}/`, data)
        .then(res => {
            dispatch({
                type: VIDEO_RECORDER.EDIT,
                payload: {isReloadList: false},
            });
            dispatch(videoRecorderClearState());
            dispatch(videoRecorderList());
        })
        .catch(err => {
            dispatch({
                type: VIDEO_RECORDER.EDIT,
                payload: {
                    isReloadList: true,
                    isError: true,
                    msg: err.toString()
                },
            });
        });
};